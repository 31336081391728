<template>
  <div>
    <v-container fluid>
      <v-row><v-col> 
        <v-card max-width="800">
              <template v-if="BucketList.imageUrl">
                <v-img
                  class="white--text align-end"
                  :src="BucketList.imageUrl"
                >
                  <v-card-title>{{ BucketList.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ BucketList.description }}
                  </v-card-subtitle>
                </v-img>
              </template>
              <template v-else>
                <v-img
                  class="white--text align-end"
                  src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                >
                  <v-card-title>{{ BucketList.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ BucketList.description }}
                  </v-card-subtitle>
                </v-img>
              </template>

              <v-card-subtitle class="pb-0" v-if="BucketList.bucketListEntries"
                >Einträge: {{ BucketList.bucketListEntries.length }}
              </v-card-subtitle>

              <v-card-text class="text--primary mx-auto">
                <template v-if="BucketList.bucketListEntries">
                  <v-row
                    class="border-bottom: thin gray solid;"
                    v-for="entry in BucketList.bucketListEntries"
                    :key="entry.id"
                    dense
                  >
                    <v-col dense>
                      <v-checkbox disabled
                        v-model="entry.isChecked"
                      />
                    </v-col>
                    <v-col cols="8" dense class="mt-4">
                      <template v-if="entry.isChecked">
                        <span style="text-decoration: line-through">{{
                          entry.entry
                        }}</span>
                      </template>
                      <template v-else>
                        <span>{{ entry.entry }}</span>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
        </v-col></v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  data() {
    return {
      text: [],
      confirmBar: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    BucketList() {
      let x = this.$store.state.bucketlists.selected;
      return x.item;
    },
    isLoading() {
      return this.$store.state.bucketlists.selected.loading;
    },
  },
  methods: {
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("bucketlists/getSingle", this.id);
    }
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="photos">
      <PhotoItem
        v-for="photo in photos"
        :key="photo.id"
        :alt="photo.alt_description"
        :author="photo.user.name"
        :image-url="photo.urls.thumb"
        @click.native="select(photo)"
      />
    </div>
  </div>
</template>

<script>
import PhotoItem from './PhotoItem.vue';
export default {
  components: { PhotoItem },
  props: {
    photos: [],
    selectedPhoto: {}
  },
  data() {
    return {
         SelectedPhoto:  this.selectedPhoto
    }
  },
  methods: {
    select(photo) {
      console.log(photo);
        this.SelectedPhoto = { 
          author: photo.user.name,
          authorUrl: "https://unsplash.com/@" + photo.user.username + '?utm_source=germanoutdoors&utm_medium=referral',
          url: photo.urls.small
        };
    }
  },
  watch: {
    SelectedPhoto: {
      deep: true,
      handler() {
        this.$emit("selectedPhoto", this.SelectedPhoto);
      },
    },
  }
};
</script>

<style scoped>
.wrapper {
  height: 600px;
  overflow-y: auto;
}
.photos {
  column-count: 3;
}
@media screen and (min-width: 576px) {
  .photos {
    column-count: 4;
  }
}
</style>
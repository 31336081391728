<template>
  <v-container fluid>
    <v-row>
      <v-col class="mx-auto">
        <v-dialog transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile color="primary" v-bind="attrs" v-on="on"
              >Neue Bucket List anlegen</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Neue Bucket List hinzufügen</v-toolbar
              >
              <v-card-text>
                <v-form v-model="valid" class="mx-auto">
                  <v-text-field
                    v-model="Bucketlist.Title"
                    :rules="inputRules"
                    label="Titel"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="Bucketlist.Description"
                    label="Beschreibung"
                    required
                  ></v-text-field>

                  <SearchForm :photoUrl="photoUrl" @photoUrl="getPhotoUrl"></SearchForm>

                  <v-text-field
                    v-model="Bucketlist.ImageUrl"
                    label="Bild aus Webadresse"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Schließen</v-btn>
                <v-btn tile color="primary" @click="Save"
                  >Anlegen</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchForm from '../unsplash/SearchForm.vue';
export default {
    data() {
        return {
            valid: false,
            dialog: false,
            photoData: {},
            Bucketlist: {
                Title: "",
                Description: "",
                ImageUrl: "",
            },
            inputRules: [
                (v) => !!v || "Pflichtfeld",
                (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
            ],
        };
    },
    methods: {
      getPhotoUrl(data) {
        console.info(data);
        this.photoData = data;
        this.Bucketlist.ImageUrl = this.photoData.url;
      },
        Reset() {
            this.Bucketlist.Name = "";
            this.Bucketlist.Description = "";
            this.Bucketlist.ImageUrl = "";
            this.dialog = false;
        },
        Save() {
            let dto = {
                Title: this.Bucketlist.Title,
                Description: this.Bucketlist.Description,
                Copyright: 'Photo by <a href="' + this.photoData.authorUrl + '">' + this.photoData.author + '</a> on <a href="https://unsplash.com/?utm_source=germanoutdoors&utm_medium=referral">Unsplash</a>',
                ImageUrl: this.Bucketlist.ImageUrl,
            };
            this.$store.dispatch("bucketlists/create", dto).then(() => {
                this.$root.$emit("reload_bucketlist_data");
                this.Reset();
            });
        },
    },
    components: { SearchForm }
};
</script>

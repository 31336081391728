<template>
  <v-list nav dense>
    <v-subheader><b>Bucket List App</b></v-subheader>
    <v-list-item link>
    <v-list-item-icon>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-list-item-icon>
    <v-list-item-title
        ><router-link to="/">
          Bucket Listen
        </router-link></v-list-item-title
      >
    </v-list-item>

    <v-subheader><b>Navigation</b></v-subheader>
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        ><a href="https://www.german-outdoors.de">
          Zur Hauptseite
        </a></v-list-item-title
      >
    </v-list-item>

    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-account-supervisor</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        ><a href="https://community.german-outdoors.de">
          Community
        </a></v-list-item-title
      >
    </v-list-item>

   

  </v-list>
</template>

<script>
import { getUsername, getInitials } from "../../helpers";

export default {
  data() {
    return {
      communityVisible: false,
      helpVisible: false,
      downloadsVisible: false
    };
  },
  mounted() {
    
  },
  computed: {
    username() {
      return getUsername();
    },
    initials() {
      return getInitials();
    },
  },
  methods: {
    getLogoutURL() {
      return process.env.VUE_APP_KEYCLOAK_ENDSESSION_ROUTE;
    },

    revertVisibleCommunity() {
      this.communityVisible = this.communityVisible ? false : true;
    },
    revertVisibleHelp() {
      this.helpVisible = this.helpVisible ? false : true;
    },
    revertVisibleDownloads() {
      this.downloadsVisible = this.downloadsVisible ? false : true;
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: #3b3b3b;
  font-size: 14px;
}
.theme--light.v-list-item--active {
  background-color: #38633a !important;
}

.v-list-item--active.v-list-item--link.theme--light > div.v-list-item__title {
  color: white !important;
}

div.v-list-item__title > a {
  color: black;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
  font-size: 14px;
}

div.v-list-item__title {
  color: black;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
  font-size: 14px;
}

div.v-list-group.v-list-group--active.primary--text
  > div.v-list-group__items
  > div
  > div.v-list-item__title
  > a {
  color: #38633a !important;
  padding-left: 7%;
}
</style>

<style>
.theme--light.v-list-item--active {
    background-color: white !important; 
}

div.v-list-item__content div {
  color: #212832;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
 font-size: 14px;
}

.v-list-group__items {
  border-left: thin #d4dae3 solid;
  margin-left: 1em;
}

.v-list-item__title {
    color: #212832;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
 font-size: 14px;
}

.v-list-group__items div.v-list-item {
  margin-left: 2.4em;
}

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Bucketlist from "../components/views/Bucketlist"
import Share from "../components/views/Share"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Bucketlist,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/Share/:id',
    name: 'Share',
    component: Share,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      isAuthenticated: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
    } else if (Vue.$keycloak.hasResourceRole(process.env.VUE_APP_KEYCLOAK_ROLE)) {
      // The user was authenticated, and has the app role
      Vue.$keycloak.updateToken(70)
        .then(() => {
          localStorage.setItem("vue-refresh-token",  Vue.$keycloak.refreshToken);
          next()
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      localStorage.setItem("vue-token",  Vue.$keycloak.token);
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page
      next()
    }
  } else {
    localStorage.setItem("vue-token",  Vue.$keycloak.token);
    // This page did not require authentication
    next()
  }
})

export default router

<template>
  <div class="photo">
    <img :src="imageUrl" :alt="alt" loading="lazy">
    <div class="centered">{{ author }}</div>
  </div>
</template>

<script>
export default {
  props: ['imageUrl', 'alt', 'author'],
}
</script>

<style scoped>
.photo {
  margin-bottom: 5px;
}
img {
  width: 100%;
  border-radius: 6px;
}

/* Centered text */
.centered {
  background-color: rgba(26, 26, 26, 0.3);
  color: white;
  padding-left: 1em;
  position: relative;
  margin-top: -4em;
  margin-bottom: 4em;
}
</style>
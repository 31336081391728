<template>
  <div>
    <v-app-bar color="white" app>
        <v-col cols="6">
          <v-app-bar-nav-icon
            color="primary"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-col>
        <!--<v-col cols="2" class="mb-0 pb-0">
          <v-text-field
            dense
            class="mb-0 pb-0 noslots"
            placeholder="Nach Ausrüstung suchen..."
            outlined
          ></v-text-field>
        </v-col>-->
        <v-col cols="6"> </v-col>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app>
        <Sidebar></Sidebar>
      </v-navigation-drawer>

      <v-main app>
        <v-container fluid class="nopadding">
          <router-view></router-view>
        </v-container>
      </v-main>
  </div>
</template>

<script>
import { getUsername, getInitials } from "../helpers";
import Sidebar from "../components/shared/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      drawer: true,
      page: {
        url: "",
      },
      snackbarText: "",
      snackbar: false,
    };
  },

  computed: {
    username() {
      return getUsername();
    },
    initials() {
      return getInitials();
    },
    sidebarCols() {
      return this.drawer ? 2 : 1;
    },
    routerCols() {
      return this.drawer ? 10 : 12;
    },
    getUser() {
      let msg = this.$store.state.authentication.user;
      return msg;
    },
    dialogMsg() {
      let msg = this.$store.state.alert.message;
      console.log(msg);
      return msg;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    alert() {
      return this.$store.state.alert;
    },
  },
};
</script>

<style>
.strong {
  font-weight: 600 !important;
  padding: 12px;
  color: #fff !important;
  font-size: 14px;
  font-family: Lato, sans-serif;
}
.theme--light {
  background: white !important;
}

.logo-block a {
  font-size: medium !important;
  margin-top: 0px;
}

.topfixure {
  top: 0 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.nomp {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>

<style scoped>
ul,
li {
  display: inline;
}

li {
  margin-left: 12px;
}

a,
li,
span,
p,
i,
b {
  font-size: medium !important;
  color: #b0b0b0 !important;
}

.logo-block {
  left: auto;
  right: auto;
}

.nopadding {
  padding: 0px;
}

.nodeco {
  text-decoration: none;
}

.v-toolbar__content {
  height: 50px !important;
}
</style>

<template>
  <v-app>
      <template v-if="shareRoute">
        <FrameLayout></FrameLayout>
      </template>
      <template v-else>
            <LayoutDefault></LayoutDefault>
      </template>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
        <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="520">
        <v-card>
          <v-card-title class="headline">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text> {{ dialogMsg }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="clear">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--<v-footer style="z-index: 9999">
      <v-container fluid>
        <v-row>
          <Apps></Apps>
        </v-row>
      </v-container>
       
    </v-footer>-->
  </v-app>
</template>

<script>
import LayoutDefault from "./layouts/default";
import FrameLayout from "./layouts/frameLayout";

export default {
  name: "app",

  //Components
  components: {
    LayoutDefault,
    FrameLayout
  },

  //Data
  data() {
    return {
      page: {
        url: "",
      },
      snackbarText: "",
      snackbar: false,
    };
  },

  //Lifecycle
  mounted() {
    this.$root.$on("show_snackbar", (msg) => {
      this.snackbarText = msg;
      this.snackbar = true;
    });

    this.$root.$on("show_dialog", (title, msg) => {
      this.$store.dispatch("alert/success", title, msg);
    });
  },

  //Logic
  computed: {
    notifierCookieAvailable: {
      get: function () {
        return localStorage.getItem("noNotification") != 1;
      },
    },
    dialog() {
      let v =
        this.$store.state.alert.message != "" &&
        this.$store.state.alert.message != undefined &&
        this.$store.state.alert.message != null;
      return v;
    },
    getUser() {
      let msg = this.$store.state.authentication.user;
      console.log("User");
      console.log(msg);
      return msg;
    },
    dialogMsg() {
      let msg = this.$store.state.alert.message;
      console.log(msg);
      return msg;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    alert() {
      return this.$store.state.alert;
    },
    shareRoute() {
      let name = this.$router.currentRoute.path;
      return name.startsWith("/share/");
    },
  },
  methods: {
    clear() {
      this.$store.dispatch("alert/clear");
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>

<style >
#app {
  min-height: 100%;
  font-family:Lato,sans-serif;
  font-size:14px;
  color:#000;
}

h2,
h3,
h4,
h5 {
  font-family:Lato,sans-serif;
  /*font-weight: normal;*/
}

.color_table th {
  background-color: #778a7d !important;
  color: white;
}

.color_table th > span {
  font-weight: bold;
  color: white;
}

.color_table_secondary th {
  background-color: #878787 !important;
  color: white;
}

.color_table_secondary th > span {
  font-weight: bold;
  color: white;
}

.v-input--switch__track,
.v-input--switch__thumb {
  background-color: #778a7d !important;
}
</style>
<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h2>Deine Bucket Listen</h2>
          <span>
            Deine Bucket List ist eine Liste mit Zielen und Dingen die Du noch erleben möchtest. Trage hier z.B. Deine Reiseziele oder Abenteuer Ideen ein.
          </span>
        </v-col>
      </v-row>
    </v-container>

    <NewBucketList></NewBucketList>

    <v-container v-if="bucketlists" fluid>
      <template v-if="bucketlists.items">
        <v-row>
          <v-col
            xs="12"
            sm="6"
            md="4"
            lg="3"
            v-for="list in bucketlists.items"
            :key="list.id"
          >
            <v-card max-width="390">
              <template v-if="list.imageUrl">
                <v-img
                  class="white--text align-end"
                  :src="list.imageUrl"
                  max-width="380"
                >
                  <v-card-actions style="right: 0; top: 0; position: absolute;">
                    <v-btn @click="showConfirmBar(list)" color="error"
                      ><i class="fa fa-trash-alt"></i
                    ></v-btn>
                  </v-card-actions>
                  <v-card-title>{{ list.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ list.description }}<br/>
                     <div v-html="list.copyright"></div>
                  </v-card-subtitle>
                </v-img>
              </template>
              <template v-else>
                <v-img
                  class="white--text align-end"
                  src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ax-width="380"
                >
                <v-card-actions style="right: 0; top: 0; position: absolute;">
                    <v-btn @click="showConfirmBar(list)" color="error"
                      ><i class="fa fa-trash-alt"></i
                    ></v-btn>
                  </v-card-actions>
                  <v-card-title>{{ list.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ list.description }}
                  </v-card-subtitle>
                </v-img>
              </template>

              <v-card-subtitle class="pb-0"
                >Einträge: {{ list.bucketListEntries.length }}
              </v-card-subtitle>

              <v-card-text class="text--primary mx-auto">
                <template v-if="list.bucketListEntries">
                  <v-row
                    class="border-bottom: thin gray solid;"
                    v-for="entry in list.bucketListEntries"
                    :key="entry.id"
                    dense
                  >
                    <v-col dense>
                      <v-checkbox
                        v-model="entry.isChecked"
                        @change="changedCheckedState(entry.id)"
                      />
                    </v-col>
                    <v-col cols="8" dense class="mt-4">
                      <template v-if="entry.isChecked">
                        <span style="text-decoration: line-through">{{
                          entry.entry
                        }}</span>
                      </template>
                      <template v-else>
                        <span>{{ entry.entry }}</span>
                      </template>
                    </v-col>
                    <v-col dense>
                      <v-btn @click="DeleteEntry(entry.id)" color="secondary"
                        ><i class="fa fa-trash-alt"></i
                    ></v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
              <v-divider></v-divider>
              <v-row
                ><v-col
                  ><h3 class="ml-3 mt-1">Neuen Eintrag hinzufügen</h3></v-col
                ></v-row
              >
              <v-row class="mx-auto">
                <v-col cols="9">
                  <v-text-field v-model="text[list.id]"></v-text-field>
                </v-col>
                <v-col>
                  <v-btn @click="AddEntry(list.id, text[list.id])" color="primary"
                    ><i class="fa fa-save"></i
                  ></v-btn>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-btn @click="ShareItem(list)" color="accent" link v-if="!list.shareCode"
                  ><i class="fa fa-share"></i>Bucketliste teilen
                </v-btn>

                 <v-btn @click="OpenItem(list)" color="accent" v-if="list.shareCode"
                  ><i class="fa fa-globe"></i>Webadresse
                </v-btn>

                <v-btn @click="OpenHtmlItem(list)" color="accent" v-if="list.shareCode"
                  ><i class="fa fa-code"></i>HTML Code
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <v-dialog
      v-model="infoDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Bucket Liste teilen
        </v-card-title>

        <v-card-text>
         Sie können Ihre Bucket Liste mit anderen Personen im Internet teilen. Nutzen Sie dazu entweder den direkten Link zur Bucket Liste oder binden Sie die Bucket Liste über den HTML Code in Ihre Website ein.
        </v-card-text>
        <v-divider></v-divider>
        <br/>
        <v-card-text>
         <code>{{ dialogText }}</code>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="infoDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000"  style="z-index: 9999999999 !important;">
        Möchten Sie die Liste wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import NewBucketList from "../bucketlist/NewBucketList.vue";
import moment from "moment";
//import marked from 'marked';

export default {
  created() {
    //this.getUITexts();
    this.getData();
    
  },
  mounted() {
    this.$root.$on("reload_bucketlist_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      text: [],
      selectedItem: null,
      confirmBar: false,
      infoDialog: false,
      dialogText: ""
      //uiTexts: null
    };
  },
  computed: {
    bucketlists() {
      return this.$store.state.bucketlists.all;
    },
    isLoading() {
      return this.$store.state.bucketlists.all.loading;
    },
  },
  methods: {
    /*markdownToHtml(input) {
      return marked(input);
    },*/
    /*async getUITexts() {
      let baseUrl = process.env.VUE_APP_STRAPI_URL;
      let route = "/api/bucket-list-app";
      let requestUrl = baseUrl + route;

      const requestOptions = {
          method: 'GET'
      };

      console.log(requestUrl);

      fetch(requestUrl,  requestOptions).then((response) => {
            response.text().then(text => {
            const data = text && JSON.parse(text);
            this.uiTexts = data.data.attributes;
            console.info(this.uiTexts);
        });
      });
    },*/
    changedCheckedState(item) {
      if (item == null) return;

      this.$store.dispatch("bucketlists/switchEntryChecked", item).then(() => {
        this.$root.$emit("reload_bucketlist_data");
      });
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("bucketlists/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store.dispatch("bucketlists/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_bucketlist_data");
      });
    },
    ShareItem(item) {
      if (item == null) return;
      this.$store.dispatch("bucketlists/share", item).then(() => {
        this.$root.$emit("reload_bucketlist_data");
      });
    },
    OpenItem(item) {
      if (item == null) return;
      this.dialogText = "https://buckets.german-outdoors.de/#/Share/" + item.shareCode;
      this.infoDialog = true;
    },
     OpenHtmlItem(item) {
      if (item == null) return;
      let url = "https://buckets.german-outdoors.de/#/Share/" + item.shareCode;
      this.dialogText = '<iframe src="' + url + '" style="overflow: hidden; width="100%; height="auto"; border: none;" scrolling="no" frameborder="0"></iframe><p><a href="https://www.german-outdoors.de/blog/die-eigene-bucket-list-erstellen-so-gehts/" style="color: black;">Erstellt mit der Bucket List App</a></p>';
      this.infoDialog = true;
    },
    Reset() {
      this.text = [];
    },
    AddEntry(id, text) {
      let dto = {
        Entry: text,
        BucketListId: parseInt(id),
        IsChecked: false,
      };
      console.log(dto);
      this.$store.dispatch("bucketlists/addEntry", dto).then(() => {
        this.$root.$emit("reload_bucketlist_data");
        this.Reset();
      });
    },
    DeleteEntry(id) {
      let dto = {
        id: parseInt(id),
      };
      this.$store.dispatch("bucketlists/deleteEntry", dto).then(() => {
        this.$root.$emit("reload_bucketlist_data");
        this.Reset();
      });
    },
  },
  components: {
    NewBucketList,
  },
};
</script>

import * as Sentry from "@sentry/vue";

import {
    authHeader
} from '../helpers';

export const bucketlistService = {
    getAll,
    getSingle,
    createNew,
    updateExisting,
    deleteExisting,
    addEntry,
    deleteEntry,
    switchEntryChecked,
    shareCode
};


const route = '/api/Bucketlists'
const route_entries = '/api/BucketlistEntries'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function getSingle(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/GetSharedBucketList/' + payload, requestOptions).then(handleResponse);
}

function addEntry(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route_entries, requestOptions).then(handleResponse);
}

function createNew(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}


function shareCode(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/ShareBucketList/' + payload.id, requestOptions).then(handleResponse);
}

function updateExisting(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.ID, requestOptions).then(handleResponse);
}

function deleteExisting(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.id, requestOptions).then(handleResponse);
}

function deleteEntry(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route_entries + '/' + payload.id, requestOptions).then(handleResponse);
}

function switchEntryChecked(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    
    console.log(payload);
    return fetch(process.env.VUE_APP_API_ROUTE + route_entries + '/SwitchCheckedStateBucketListEntry/' + payload, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        console.log(text);
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('vue-token');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            Sentry.captureException(error);
            return Promise.reject(error);
        }

        return data;
    });
}
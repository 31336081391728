import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#446d51",
        secondary: "#C9C5C5",
        info: "#38633A",
        accent: "#778A7D",
        error: "#E63338",
        success: "#23cf5f",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
